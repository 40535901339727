import React from 'react';
import { styled } from '../../utils/styled';
import { Typography } from '../Typography';

const StyledTypography = styled(Typography)`
  width: 100%;
`;
export interface MainTitleProps {
  readonly title: string;
  readonly subtitle?: string;
}

export function MainTitle({ title, subtitle }: MainTitleProps) {
  return (
    <>
      <Typography noWrap title={title} variant="h3">
        {title}
      </Typography>
      <StyledTypography
        color="text.secondary"
        noWrap
        title={subtitle}
        variant="subtitle"
      >
        {subtitle}
      </StyledTypography>
    </>
  );
}

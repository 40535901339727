import { styled } from 'ui';
import { Spacing } from '../../design-system/spacing';

export const DotsContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SwipeContainer = styled('div')`
  width: 100%;
  overflow: hidden;
`;

export const SpikeContainer = styled('div')<{
  position: number;
  isHolding: boolean;
}>`
  transform: translateX(-${({ position }) => position}px);
  transition: ${({ isHolding }) =>
    isHolding ? 'none' : 'transform 0.3s ease'};
  width: calc(300% + 96px);
  overflow: hidden;
  width: 300%;
  display: flex;
  gap: ${({ theme }) => theme.spacing(Spacing.XXLarge)};
`;

type NavigationDotsProps = {
  active: boolean;
};

export const NavigationDots = styled('button')<NavigationDotsProps>`
  width: ${({ theme }) => theme.spacing(Spacing.Small)};
  height: ${({ theme }) => theme.spacing(Spacing.Small)};
  border: none;
  cursor: pointer;
  margin: ${({ theme }) =>
    `${theme.spacing(Spacing.Small)} ${theme.spacing(Spacing.Small)}`};
  border-radius: ${({ theme }) => theme.borderRadii.circular};
  background-color: ${(props) =>
    props.active
      ? props.theme.palette.primary.main
      : props.theme.palette.primary.light};
`;

import React from 'react';
import { styled } from '../../utils/styled';
import { Typography } from '../Typography';
import {
  ContentWrapper,
  StyledModalContent,
  StyledWrapper,
} from './WarningContent.style';

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'width',
})<{ width?: number }>`
  text-align: center;
  width: ${({ width }) => (width ? width : 'auto')};
`;

export function WarningContent() {
  return (
    <StyledWrapper>
      <StyledModalContent>
        <ContentWrapper>
          <StyledTypography variant="h4">Changes not saved</StyledTypography>
          <StyledTypography variant="body" width={310}>
            Are you sure you want to close the filter options without applying
            them? Changes will be lost if they're not applied.
          </StyledTypography>
        </ContentWrapper>
      </StyledModalContent>
    </StyledWrapper>
  );
}

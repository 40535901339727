import {
  Typography as TypographyMui,
  TypographyProps as TypographyPropsMui,
} from '@mui/material';
import React from 'react';

export type TypographyProps = Pick<
  TypographyPropsMui,
  'children' | 'variant' | 'color' | 'noWrap' | 'component' | 'title' | 'id'
>;

export function Typography({ ...rest }: TypographyProps) {
  return <TypographyMui {...rest} />;
}

import { PopoverVirtualElement, styled } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import React, { ReactElement } from 'react';
import { Spacing } from '../../design-system/spacing';

const StyledPaper = styled(Paper)`
  width: 320px;
  margin-top: ${({ theme }) => theme.spacing(Spacing.Small)};
`;

const StyledMenuList = styled(MenuList)`
  padding: 0px;
`;

const StyledMenuItem = styled(MenuItem)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const StyledGrow = styled(Grow)(() => ({
  transformOrigin: 'right top',
}));

type ListItem = {
  onClick: () => void;
  title: string;
  icon?: ReactElement;
};

interface PopoverMenuProps {
  readonly anchorEl: HTMLElement | PopoverVirtualElement | null;
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly menuItems: ListItem[];
  readonly heading?: React.ReactNode;
}

export function PopoverMenu({
  anchorEl,
  isOpen,
  onClose,
  menuItems,
  heading = null,
}: PopoverMenuProps) {
  return (
    <Popper
      anchorEl={anchorEl}
      disablePortal
      open={isOpen}
      placement="bottom-start"
      transition
    >
      {({ TransitionProps }) => (
        <StyledGrow {...TransitionProps}>
          <StyledPaper>
            <ClickAwayListener onClickAway={onClose}>
              <StyledMenuList
                aria-labelledby="composition-button"
                autoFocusItem={isOpen}
                id="composition-menu"
              >
                {heading}
                {menuItems.map(({ onClick, title, icon }) => (
                  <StyledMenuItem key={title} onClick={onClick}>
                    <ListItemText>{title}</ListItemText>
                    {icon}
                  </StyledMenuItem>
                ))}
              </StyledMenuList>
            </ClickAwayListener>
          </StyledPaper>
        </StyledGrow>
      )}
    </Popper>
  );
}

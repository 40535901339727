import { Grid as GridMui, GridProps as GridPropsMui } from '@mui/material';
import React from 'react';

export type GridProps = Pick<
  GridPropsMui,
  | 'children'
  | 'columns'
  | 'component'
  | 'container'
  | 'direction'
  | 'item'
  | 'lg'
  | 'md'
  | 'mb'
  | 'mt'
  | 'mr'
  | 'ml'
  | 'sm'
  | 'rowSpacing'
  | 'spacing'
  | 'xl'
  | 'xs'
  | 'width'
  | 'height'
  | 'padding'
  | 'justifyContent'
  | 'alignItems'
  | 'flexWrap'
  | 'flexDirection'
  | 'gap'
>;

export function Grid({ ...rest }: GridProps) {
  return <GridMui {...rest} />;
}

import { styled } from '@mui/material';
import React from 'react';
import { Spacing } from '../../design-system/spacing';
import { Card } from '../Card';
import { Collapse } from '../Collapse';
import { SummaryCardField } from './SummaryCardField';
import { SummaryCardGroupFields } from './SummaryCardGroupFields';
import { SummaryCardHeader } from './SummaryCardHeader';
import { SummaryCardTitle } from './SummaryCardTitle';

export const StyledSummaryCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing(Spacing.Medium)};
  flex: 1;
  align-items: flex-start;
  border-radius: ${({ theme }) => theme.borderRadii.outer};
  width: 100%;
  box-shadow: ${({ theme }) => theme.boxShadow};
  display: flex;
  flex-direction: column;
`;

const StyledCollapse = styled(Collapse)`
  width: 100%;
`;

interface SummaryCardProps {
  readonly header: React.ReactNode;
  readonly isCollapsible?: boolean;
  readonly expandCard?: boolean;
}

export function SummaryCard({
  header,
  children,
  isCollapsible = false,
  expandCard,
}: React.PropsWithChildren<SummaryCardProps>) {
  return (
    <StyledSummaryCard>
      {header}
      {isCollapsible ? (
        <StyledCollapse data-testid="CardContent" in={expandCard}>
          {children}
        </StyledCollapse>
      ) : (
        children
      )}
    </StyledSummaryCard>
  );
}

SummaryCard.Header = SummaryCardHeader;
SummaryCard.Title = SummaryCardTitle;
SummaryCard.Field = SummaryCardField;
SummaryCard.GroupFields = SummaryCardGroupFields;

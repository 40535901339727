import {
  SpeedDialAction as SpeedDialActionMui,
  SpeedDialActionProps as SpeedDialActionPropsMui,
  styled,
} from '@mui/material';
import React from 'react';
import { Spacing } from '../../../../design-system/spacing';

export type SpeedDialActionProps = SpeedDialActionPropsMui;

const StyledSpeedDialAction = styled(SpeedDialActionMui)`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(Spacing.Small)};
  text-transform: capitalize;
  padding: ${({ theme }) => theme.spacing(Spacing.Small, Spacing.Medium)};
  // setting a very high border radius here since applying theme's borderRadii.circular of 50% is not working.
  border-radius: 1000px;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: -0.01em;

  &::after {
    content: '${({ tooltipTitle }) => tooltipTitle as string}';
  }
`;

export function SpeedDialAction({ ...rest }: SpeedDialActionProps) {
  return <StyledSpeedDialAction {...rest} />;
}

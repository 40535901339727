import {
  CheckboxProps as CheckboxPropsMui,
  Checkbox as CheckboxUi,
  FormControlLabel,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { ColorNeutral300, ColorNeutral600 } from '../../design-system/colors';
import { styled } from '../../utils/styled';
import { Typography } from '../Typography';

const StyledCheckboxUi = styled(CheckboxUi)`
  &.Mui-checked:hover {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`;
export interface CheckboxProps
  extends Pick<
    CheckboxPropsMui,
    'checked' | 'name' | 'onChange' | 'icon' | 'checkedIcon'
  > {
  readonly label: string;
  readonly value?: string;
  readonly disabled?: boolean;
  readonly additionalLabel?: ReactNode;
}

export function Checkbox({
  disabled,
  value,
  label,
  additionalLabel,
  ...props
}: CheckboxProps) {
  return (
    <FormControlLabel
      control={
        <StyledCheckboxUi {...props} inputProps={{ 'aria-label': label }} />
      }
      disabled={disabled}
      label={
        <Typography
          color={disabled ? ColorNeutral300 : ColorNeutral600}
          variant="body"
        >
          {label}
          {additionalLabel}
        </Typography>
      }
      value={value}
    />
  );
}

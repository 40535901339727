import {
  Button as ButtonMui,
  ButtonProps as ButtonPropsMui,
} from '@mui/material';
import React, { forwardRef, ReactNode, Ref } from 'react';
import { Spacing } from '../../design-system/spacing';
import { styled } from '../../utils/styled';
import { Typography } from '../Typography';

export type ColorOptions = 'primary' | 'destructive' | 'neutral' | 'info';
export type VariantOptions = 'contained' | 'outlined' | 'text';

const colorMapper: Record<ColorOptions, ButtonPropsMui['color']> = {
  primary: 'primary',
  destructive: 'error',
  neutral: 'text',
  info: 'info',
};

export const IconWrapper = styled('div')(() => ({
  width: 24,
  height: 24,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledButton = styled(ButtonMui)(({
  theme,
  variant,
  color,
  startIcon,
  ...rest
}) => {
  // Calculate padding based on variant and presence of aria-label
  let padding = `${theme.spacing(Spacing.Small)}`;

  if (variant !== 'text') {
    if (rest['aria-label']) {
      padding = `${theme.spacing(Spacing.Small)} ${theme.spacing(Spacing.Medium)}`;
    } else {
      padding = `${theme.spacing(Spacing.Small)}`;
    }
  }

  // Update textDecoration and backgroundColor based on conditions
  let textDecoration = 'none';
  let backgroundColor;

  if (!(variant || startIcon)) {
    textDecoration = 'underline';
  }

  if (!(variant || startIcon || rest['aria-label'])) {
    backgroundColor = 'transparent';
  }

  return {
    height: theme.spacing(Spacing.XLarge),
    '&:hover': {
      textDecoration,
      backgroundColor,
      boxShadow: 'none',
    },
    '&:disabled': {
      border: 'none',
    },
    borderRadius: theme.borderRadii.outer,
    textTransform: 'capitalize',
    color:
      variant === 'contained' && color === 'text'
        ? theme.palette.text.primaryInverted
        : undefined,
    padding,
    minWidth: '0px',
    boxShadow: 'none',
    border:
      variant === 'contained' && color !== 'error'
        ? `1px solid ${theme.palette.primary.main}`
        : undefined,
  };
});

export interface ButtonProps
  extends Omit<ButtonPropsMui, 'color'>,
    Pick<ButtonPropsMui, 'disabled' | 'fullWidth' | 'children'> {
  readonly label?: string;
  readonly color?: ColorOptions;
  readonly variant?: VariantOptions;
  readonly startIcon?: ReactNode;
  readonly ml?: string | number;
  readonly mr?: string | number;
  readonly mt?: string | number;
  readonly mb?: string | number;
  readonly pl?: string | number;
  readonly 'data-testid'?: string;
}

export const Button = forwardRef(
  (
    {
      label,
      color = 'primary',
      variant = 'contained',
      mr,
      ml,
      mt,
      mb,
      pl,
      startIcon,
      'data-testid': dataTestId,
      ...props
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>,
  ) => (
    <StyledButton
      {...props}
      aria-label={label || props['aria-label']}
      color={colorMapper[color]}
      data-testid={dataTestId}
      ref={ref}
      startIcon={
        label && startIcon ? <IconWrapper>{startIcon}</IconWrapper> : null
      }
      sx={{
        marginRight: mr,
        marginLeft: ml,
        marginTop: mt,
        marginBottom: mb,
        paddingLeft: pl,
      }}
      variant={variant}
    >
      {label ? <Typography variant="subtitle">{label}</Typography> : null}
      {!label && startIcon ? <IconWrapper>{startIcon}</IconWrapper> : null}
      {props.children}
    </StyledButton>
  ),
);

export const countries = [
  {
    name: 'Canada',
    flag: '🇨🇦',
    code: 'CA',
    dialCodes: [
      '+1905',
      '+1873',
      '+1902',
      '+1867',
      '+1825',
      '+1807',
      '+1819',
      '+1782',
      '+1780',
      '+1778',
      '+1705',
      '+1587',
      '+1647',
      '+1709',
      '+1639',
      '+1613',
      '+1604',
      '+1581',
      '+1519',
      '+1579',
      '+1514',
      '+1506',
      '+1450',
      '+1438',
      '+1437',
      '+1431',
      '+1418',
      '+1416',
      '+1365',
      '+1403',
      '+1343',
      '+1306',
      '+1250',
      '+1289',
      '+1236',
      '+1226',
      '+1249',
      '+1204',
    ],
  },
  {
    name: 'Guam',
    flag: '🇬🇺',
    code: 'GU',
    dialCodes: ['+1671'],
  },
  {
    name: 'Northern Mariana Islands',
    flag: '🇲🇵',
    code: 'MP',
    dialCodes: ['+1670'],
  },
  {
    name: 'Puerto Rico',
    flag: '🇵🇷',
    code: 'PR',
    dialCodes: ['+1939'],
  },
  {
    name: 'Trinidad and Tobago',
    flag: '🇹🇹',
    code: 'TT',
    dialCodes: ['+1868'],
  },
  {
    name: 'Virgin Islands, British',
    flag: '🇻🇬',
    code: 'VG',
    dialCodes: ['+1284'],
  },
  {
    name: 'Virgin Islands, U.S.',
    flag: '🇻🇮',
    code: 'VI',
    dialCodes: ['+1340'],
  },
];

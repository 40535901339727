import { AppBar, Toolbar } from '@mui/material';
import { Spacing } from '../../design-system/spacing';
import { styled } from '../../utils/styled';

export const TopNavbarWrapper = styled(AppBar)`
  padding: ${({ theme }) => theme.spacing(Spacing.Medium)} 0;
  background: ${({ theme }) => theme.palette.paper[200]};
  box-shadow: ${({ theme }) => theme.boxShadow};
  color: ${({ theme }) => theme.palette.text.primary};
  width: 100%;
`;

export const TopNavbarToolbar = styled(Toolbar, {
  shouldForwardProp: (prop) => prop !== 'thin',
})<{ thin?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.MuiToolbar-root {
    height: ${({ theme, thin }) =>
      thin ? theme.spacing(Spacing.Large) : theme.spacing(Spacing.XLarge)};
    min-height: unset;
  }
`;

export const RightToolbarContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LogoWrapper = styled('div')`
  margin-right: ${({ theme }) => theme.spacing(Spacing.XXLarge)};
`;

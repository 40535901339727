import React from 'react';
import { ColorNeutral600 } from '../../design-system/colors';
import { Spacing } from '../../design-system/spacing';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import { styled } from '../../utils/styled';
import { IconButton } from '../IconButton';
import { InfoOutlinedIcon } from '../Icons/InfoOutlinedIcon';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

const StyledContainerStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isSmUp',
})<{ isSmUp: boolean }>`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ isSmUp }) => (isSmUp ? 'flex-start' : 'space-between')};
  gap: ${({ theme }) => theme.spacing(Spacing.Small)};
`;

const StyledItemsStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isSmUp',
})<{ isSmUp: boolean }>`
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: ${({ isSmUp }) => (isSmUp ? 'space-between' : 'flex-start')};
  gap: ${({ theme }) => theme.spacing(Spacing.Small)};
`;

const StyledIconStack = styled(Stack)`
  align-items: center;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(Spacing.Small)};
`;

interface SummaryCardHeaderProps {
  readonly icon: React.ReactNode;
  readonly title: string;
  readonly onClickInfo: () => void;
  readonly actionButton?: React.ReactNode;
}

export function SummaryCardHeader({
  icon,
  title,
  onClickInfo,
  actionButton,
}: SummaryCardHeaderProps) {
  const { isSmUp } = useBreakpoints();
  return (
    <StyledContainerStack isSmUp={isSmUp} mr={isSmUp ? Spacing.Small : ''}>
      <StyledItemsStack isSmUp={isSmUp}>
        <StyledIconStack>
          {icon}
          <Typography variant="h5">{title}</Typography>
        </StyledIconStack>
        <IconButton aria-label="info" onClick={onClickInfo}>
          <InfoOutlinedIcon color={ColorNeutral600} />
        </IconButton>
      </StyledItemsStack>
      {actionButton}
    </StyledContainerStack>
  );
}

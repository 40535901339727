import { Spacing } from '../../design-system/spacing';
import { styled } from '../../utils/styled';

export const StyledWrapper = styled('div')`
  height: 100%;
  width: 100%;
  position: relative;
`;

export const StyledButtonContent = styled('div')`
  position: absolute;
  display: flex;
  padding: ${({ theme }) => theme.spacing(Spacing.Medium)};
  gap: ${({ theme }) => theme.spacing(Spacing.Medium)};
  bottom: 0;
  width: 100%;
`;

export const StyledModalContent = styled('div')`
  height: 100%;
  width: 100%;
  overflow: scroll;
`;

export const ContentWrapper = styled('div')`
  min-height: 500px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-flow: column;
  width: 100%;
`;

import {
  IconButton as IconButtonMui,
  IconButtonProps as IconButtonPropsMui,
} from '@mui/material';
import React from 'react';

export type IconButtonProps = Pick<
  IconButtonPropsMui,
  'ref' | 'children' | 'onClick' | 'edge' | 'color' | 'size' | 'aria-labelledby'
>;

export function IconButton({ ...rest }: IconButtonProps) {
  return <IconButtonMui {...rest} />;
}

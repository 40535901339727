import React, { forwardRef, Ref } from 'react';
import { Spacing } from '../../design-system/spacing';
import { styled } from '../../utils/styled';
import { PageCardContainer } from '../PageCardContainer';
import { SpikeContent } from '../SpikeContent';
import { SpikeInfoBlock } from '../SpikeInfoBlock/SpikeInfoBlock';

const PageCardContainerStyled = styled(PageCardContainer, {
  shouldForwardProp: (prop) => prop !== 'isCarousel',
})<{ isCarousel?: boolean }>`
  ${({ theme, isCarousel }) =>
    isCarousel
      ? `
    width: calc(100vw - ${theme.spacing(Spacing.XXLarge)} - ${theme.spacing(Spacing.Small)});
    flex: none;
    max-width: none;
    `
      : `
    flex: 1;
    max-width: 33%;
  `}
`;
export interface SpikeCardProps {
  readonly spikeId: string;
  readonly totalClaims: number;
  readonly VSaverage: number;
  readonly isCarousel?: boolean;
  readonly title: string;
  readonly borderColor: string;
  readonly claimData: { key: string; value: string }[];
  readonly blocks: { title: string; info: { value: string; link: string }[] }[];
}

export const SpikeCard = forwardRef(
  (
    {
      spikeId,
      totalClaims,
      VSaverage,
      claimData,
      title,
      blocks,
      borderColor,
      isCarousel,
    }: SpikeCardProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    return (
      <PageCardContainerStyled
        borderColor={borderColor}
        isCarousel={isCarousel}
        ref={ref}
        title={title}
      >
        <SpikeContent
          VSaverage={VSaverage}
          claimData={claimData}
          spikeId={spikeId}
          totalClaims={totalClaims}
        />
        {blocks.map(({ title: blockTitle, info }) => (
          <SpikeInfoBlock
            info={info}
            key={`${spikeId}-spike-info-blocks`}
            title={blockTitle}
          />
        ))}
      </PageCardContainerStyled>
    );
  },
);

import { styled } from '@mui/system';
import React from 'react';
import { Spacing } from '../../design-system/spacing';
import { ElementWithBadge } from '../ElementWithBadge';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

const StyledStack = styled(Stack)`
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(Spacing.Medium)};
`;
const StyledTypography = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(Spacing.Tiny)};
`;
export interface PersonaProps {
  readonly userName: string;
  readonly companyName: string;
}

export function Persona({ userName, companyName }: PersonaProps) {
  return (
    <StyledStack>
      <ElementWithBadge
        badgeColor="error"
        element="avatar"
        name={userName}
        size="large"
      />
      <Stack>
        <StyledTypography variant="h4">{userName}</StyledTypography>
        <Typography variant="body">{companyName}</Typography>
      </Stack>
    </StyledStack>
  );
}

export function mapObjToSearchParams(obj: Record<string, unknown>) {
  const filteredObj = Object.fromEntries(
    Object.entries(obj)
      .filter(
        ([, value]) =>
          value !== null &&
          value !== undefined &&
          value !== '' &&
          value !== 'undefined',
      )
      .map(([key, value]) => [
        key,
        typeof value === 'string' ? value : JSON.stringify(value),
      ]),
  );

  return new URLSearchParams(filteredObj);
}

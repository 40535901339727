import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '../../utils/styled';
import { Typography } from '../Typography';

const BreadcrumbText = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.text.secondary};
  line-height: 21px;
  letter-spacing: -0.16px;
`;

export type NavigationItem = {
  key: string;
  label: string;
  to?: string;
};

function ClickableBreadcrumb(item: Required<NavigationItem>) {
  // ? Destructuring inside component is a work around on this: https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/no-unused-prop-types.md
  const { label, to } = item;
  return (
    <Link to={to}>
      <BreadcrumbText>{label}</BreadcrumbText>
    </Link>
  );
}

function TextBreadCrumb(item: NavigationItem) {
  // ? Destructuring inside component is a work around on this: https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/no-unused-prop-types.md
  const { label } = item;
  return <BreadcrumbText>{label}</BreadcrumbText>;
}

export const breadcrumbItemTransformer = ({
  key,
  label,
  to,
}: NavigationItem) => {
  if (to) {
    return <ClickableBreadcrumb key={key} label={label} to={to} />;
  }

  return <TextBreadCrumb key={key} label={label} />;
};

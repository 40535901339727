import { styled } from '@mui/material';
import React from 'react';
import { Spacing } from '../../design-system/spacing';
import { Typography } from '../Typography';

export const StyledTypography = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(Spacing.Small)};
`;

interface SummaryCardTitleProps {
  readonly title: string;
}

export function SummaryCardTitle({ title }: SummaryCardTitleProps) {
  return <StyledTypography variant="subtitle">{title}</StyledTypography>;
}

import React from 'react';
import { styled } from '../../utils/styled';
import { Grid } from '../Grid';
import { Skeleton } from '../Skeleton';

const NUMBER_OF_SKELETONS = 10;

type TableSkeletonProps = {
  readonly 'data-testid'?: string;
};

const TableSkeletonCell = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const renderMultipleSkeletons = (count: number) => {
  const skeletons = [];
  for (let i = 0; i < count; i += 1) {
    skeletons.push(
      <TableSkeletonCell item key={i} xs={12}>
        <Grid item>
          <Skeleton height={50} width={200} />
        </Grid>
        <Grid item>
          <Skeleton height={50} width={200} />
        </Grid>
        <Grid item>
          <Skeleton height={50} width={200} />
        </Grid>
        <Grid item>
          <Skeleton height={50} width={200} />
        </Grid>
        <Grid item>
          <Skeleton height={50} width={200} />
        </Grid>
      </TableSkeletonCell>,
    );
  }
  return skeletons;
};

export function TableSkeleton({
  'data-testid': dataTestId,
}: TableSkeletonProps) {
  return (
    <Grid container data-testid={dataTestId}>
      <Grid item xs={6}>
        <Skeleton height={50} variant="text" width={500} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton height={50} variant="text" />
      </Grid>
      {renderMultipleSkeletons(NUMBER_OF_SKELETONS)}
    </Grid>
  );
}

import { Box as BoxMui, BoxProps as BoxPropsMui } from '@mui/material';
import React from 'react';

export interface BoxProps
  extends Pick<BoxPropsMui, 'children' | 'onMouseEnter' | 'onMouseLeave'> {
  readonly mb?: string | number;
  readonly mt?: string | number;
  readonly ml?: string | number;
  readonly mr?: string | number;
  readonly pt?: string | number;
  readonly pb?: string | number;
  readonly pl?: string | number;
  readonly pr?: string | number;
  readonly margin?: string | number;
  readonly padding?: string | number;
  readonly width?: string | number;
}

export function Box({
  mb,
  mt,
  ml,
  mr,
  pt,
  pb,
  pl,
  pr,
  margin,
  padding,
  width,
  ...rest
}: BoxProps) {
  return (
    <BoxMui
      sx={{
        marginBottom: mb,
        marginTop: mt,
        marginLeft: ml,
        marginRight: mr,
        paddingTop: pt,
        paddingBottom: pb,
        paddingLeft: pl,
        paddingRight: pr,
        margin,
        padding,
        width,
      }}
      {...rest}
    />
  );
}

import React from 'react';
import { Spacing } from '../../design-system/spacing';
import { styled } from '../../utils/styled';
import { Box } from '../Box';
import { Divider } from '../Divider';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-left: ${({ theme }) => theme.spacing(Spacing.Small)};
  overflow: hidden;
`;
export const StyledTypographyWidth = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-left: ${({ theme }) => theme.spacing(Spacing.Small)};
  overflow: hidden;
  width: ${({ theme }) => theme.spacing(Spacing.XXLarge)};
`;
export const StyledStack = styled(Stack)`
  background-color: ${({ theme }) => theme.palette.text.primaryInverted};
  width: 100%;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(Spacing.Small)};
`;
export const StyledItemStack = styled(Stack)`
  background-color: ${({ theme }) => theme.palette.text.primaryInverted};
  width: 100%;
  justify-content: flex-start;
  padding: ${({ theme }) => theme.spacing(Spacing.Tiny)};
  flex: 1;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(Spacing.Small)};
`;
export const StyledContainerBox = styled(Box)`
  margin-bottom: ${({ theme }) => theme.spacing(Spacing.Medium)};
`;
export const StyledMarginBox = styled(Box)`
  margin-bottom: ${({ theme }) => theme.spacing(Spacing.Small)};
`;

export interface SpikeInfoBlockProps {
  readonly title: string;
  readonly info: { value: string; link: string }[];
}

export function SpikeInfoBlock({ info, title }: SpikeInfoBlockProps) {
  return (
    <StyledContainerBox>
      <StyledMarginBox>
        <Typography variant="subtitle">{title}</Typography>
      </StyledMarginBox>
      <StyledStack>
        {info.map((item, index: number) => (
          <StyledItemStack
            data-testid="spike-info-block"
            divider={<Divider flexItem light orientation="vertical" />}
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}-spike-blocks`}
          >
            <StyledTypographyWidth variant="body">
              {item.value}
            </StyledTypographyWidth>
            <StyledTypography>{item.link}</StyledTypography>
          </StyledItemStack>
        ))}
      </StyledStack>
    </StyledContainerBox>
  );
}

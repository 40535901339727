import { CardContent } from '@mui/material';
import React from 'react';
import { Spacing } from '../../design-system/spacing';
import {
  currencyFormatterInteger,
  numberFormatter,
} from '../../utils/number-formatter';
import { styled } from '../../utils/styled';
import { Stack } from '../Stack';
import { formatInput, getPercentage, getTotal } from '../StatsCard/utils';
import {
  FilledBar,
  StyledBox,
  StyledItemsStack,
  StyledTypography,
  StyledTypographyCentered,
  StyledValueStack,
} from './Percentage.style';

const StyledCardContent = styled(CardContent)`
  margin-bottom: ${({ theme }) => theme.spacing(Spacing.Tiny)};
`;
export interface PercentageBarProps {
  readonly items: { key: string; value: number }[];
  readonly format?: 'number' | 'currency';
}

export function PercentageBar({
  items,
  format = 'number',
}: PercentageBarProps) {
  const formatter =
    format === 'number' ? numberFormatter : currencyFormatterInteger;
  return (
    <StyledCardContent key="cardPercentageBar">
      <StyledValueStack key="stackPercentageBar">
        {items.map((item) =>
          item.value === 0 ? (
            ''
          ) : (
            <StyledTypography
              key={`${item.key}-typography-items`}
              variant="body"
            >
              {formatInput(String(getPercentage(item.value, getTotal(items))))}%
            </StyledTypography>
          ),
        )}
      </StyledValueStack>
      <Stack />
      <StyledBox>
        {items.map((item, index: number) =>
          item.value === 0 ? (
            ''
          ) : (
            <FilledBar
              index={index}
              key={`${item.key}-filled-Bar`}
              value={getPercentage(item.value, getTotal(items))}
            />
          ),
        )}
      </StyledBox>
      <StyledItemsStack key="stackForItems">
        {items.map((item) =>
          item.value === 0 ? (
            ''
          ) : (
            <StyledTypography
              key={`${item.key}-items-values`}
              variant="subtitle"
            >
              {formatter.format(
                format === 'currency' ? item.value / 100 : item.value,
              )}
            </StyledTypography>
          ),
        )}
      </StyledItemsStack>
      <StyledItemsStack key="stackForItems2">
        {items.map((item) =>
          item.value === 0 ? (
            ''
          ) : (
            <StyledTypographyCentered
              color="text.secondary"
              key={`${item.key}-items-total`}
              variant="caption"
            >
              {item.key}
            </StyledTypographyCentered>
          ),
        )}
      </StyledItemsStack>
    </StyledCardContent>
  );
}

import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { Spacing } from '../../design-system/spacing';
import { styled } from '../../utils/styled';

export const StyledDatePicker = styled(StaticDateRangePicker)`
  display: block;
  .MuiList-root {
    display: flex;
    max-width: 100%;
  }
  .MuiDateRangeCalendar-root {
    display: block;
  }
  .MuiDateRangePickerToolbar-container {
    height: 25px;
  }
`;

export const ButtonWrapper = styled('div')`
  padding-bottom: ${({ theme }) => theme.spacing(Spacing.Small)};
  position: relative;
  display: block;
  left: 70%;
`;

import {
  ClickAwayListener as ClickAwayListenerMui,
  ClickAwayListenerProps as ClickAwayListenerPropsMui,
} from '@mui/material';
import React from 'react';

export type ClickAwayListenerProps = Pick<
  ClickAwayListenerPropsMui,
  'children' | 'onClickAway' | 'mouseEvent'
>;

export function ClickAwayListener({ ...rest }: ClickAwayListenerProps) {
  return <ClickAwayListenerMui {...rest} />;
}

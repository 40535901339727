import { StyledComponent } from '@emotion/styled';
import { Card, CardProps } from '@mui/material';
import React from 'react';
import {
  ColorGreen600,
  ColorRed600,
  ColorYellow600,
} from '../../design-system/colors';
import { Spacing } from '../../design-system/spacing';
import { numberFormatterWithUnits } from '../../utils/number-formatter';
import { styled } from '../../utils/styled';
import { Box } from '../Box';
import { Divider } from '../Divider';
import { ArrowUpwardIcon } from '../Icons/ArrowUpwardIcon';
import { ThumbsDownIcon } from '../Icons/ThumbsDownIcon';
import { ThumbsUpIcon } from '../Icons/ThumbsUpIcon';
import { WatchLaterIcon } from '../Icons/WatchLaterIcon';
import { Stack } from '../Stack';
import { Typography, TypographyProps } from '../Typography';

export const StyledCard: StyledComponent<CardProps> = styled(Card)`
  padding: ${({ theme }) => theme.spacing(Spacing.Small)};
  align-items: flex-start;
  width: 552px;
  height: 495px;
`;
export const StyledTypography: StyledComponent<TypographyProps> = styled(
  Typography,
)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;
export const StyledBox = styled(Box)`
  margin-bottom: ${({ theme }) => theme.spacing(Spacing.Medium)};
`;
export const StyledStack = styled(Stack)`
  margin-bottom: ${({ theme }) => theme.spacing(Spacing.Medium)};
  width: 100%;
  flex-direction: row;
`;
export const StyledMarginLeftTypography = styled(Typography)`
  margin-left: ${({ theme }) => theme.spacing(Spacing.Tiny)};
`;
const StyledTotalClaimsStack = styled(Stack)`
  flex: 1;
  align-items: center;
  flex-direction: column;
`;
const StyledClaimDataStack = styled(Stack)`
  justify-content: center;
  width: 100%;
  flex-direction: row;
`;
const StyledAlignItemsStack = styled(Stack)`
  align-items: center;
  flex-direction: row;
`;
const StyledItemsStack = styled(Stack)`
  align-items: center;
  flex-direction: column;
  flex: 1;
`;

function TotalClaims({ totalClaims }: { readonly totalClaims: number }) {
  return (
    <StyledTotalClaimsStack>
      <Typography variant="h3">
        {totalClaims === 0 ? '--' : `${numberFormatterWithUnits(totalClaims)}`}
      </Typography>
      <StyledTypography variant="caption">Total Claims</StyledTypography>
    </StyledTotalClaimsStack>
  );
}

function VsAverageClaims({ VSaverage }: { readonly VSaverage: number }) {
  return (
    <StyledTotalClaimsStack>
      <Typography variant="h3">
        {VSaverage === 0 ? (
          '--'
        ) : (
          <>
            <ArrowUpwardIcon color={ColorRed600} />
            {numberFormatterWithUnits(VSaverage)}%
          </>
        )}
      </Typography>
      <StyledTypography variant="caption">VS. Average</StyledTypography>
    </StyledTotalClaimsStack>
  );
}

export const iconsList = [
  <ThumbsUpIcon color={ColorGreen600} key="thumbs-up" />,
  <WatchLaterIcon color={ColorYellow600} key="watch-later" />,
  <ThumbsDownIcon color={ColorRed600} key="thumbs-down" />,
];

export interface SpikeContentProps {
  readonly spikeId: string;
  readonly totalClaims: number;
  readonly VSaverage: number;
  readonly claimData?: { key: string; value: string }[];
}

export function SpikeContent({
  spikeId,
  totalClaims,
  VSaverage,
  claimData,
}: SpikeContentProps) {
  return (
    <StyledBox>
      <StyledStack>
        <TotalClaims totalClaims={totalClaims} />
        <VsAverageClaims VSaverage={VSaverage} />
      </StyledStack>
      <StyledClaimDataStack
        divider={<Divider flexItem light orientation="vertical" />}
      >
        {claimData?.map((item, index: number) => (
          <StyledItemsStack
            data-testid="claim-data"
            key={`${spikeId}-claim-data`}
          >
            <StyledAlignItemsStack>
              {iconsList[index]}
              <StyledMarginLeftTypography variant="subtitle">
                {item.value}
              </StyledMarginLeftTypography>
            </StyledAlignItemsStack>
            <StyledTypography variant="caption">{item.key}</StyledTypography>
          </StyledItemsStack>
        ))}
      </StyledClaimDataStack>
    </StyledBox>
  );
}

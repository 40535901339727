import styled from '@emotion/styled';
import { Box, TextField } from '@mui/material';

export const TextFieldBox = styled(TextField)`
  input {
    text-align: center;
  }
`;

export const StyledBox = styled(Box)`
  align-items: center;
  display: flex;
  gap: 20px;
`;

import {
  debounce,
  zxcvbnAsync,
  zxcvbnOptions,
  ZxcvbnResult,
} from '@zxcvbn-ts/core';
import { matcherPwnedFactory } from '@zxcvbn-ts/matcher-pwned';
import { useDeferredValue, useEffect, useState } from 'react';

export enum PasswordStrengthOptions {
  Weakest = 'weakest',
  Weak = 'weak',
  Good = 'good',
  Strong = 'strong',
  Strongest = 'strongest',
}

const USER_INPUTS = 2000;
const STRENGTH_LABELS = [
  PasswordStrengthOptions.Weakest,
  PasswordStrengthOptions.Weak,
  PasswordStrengthOptions.Good,
  PasswordStrengthOptions.Strong,
  PasswordStrengthOptions.Strongest,
];

interface PasswordStrength {
  score: number;
  feedback: {
    suggestions: string[];
    warning: string;
  };
  strengthLabel: string;
}

export const getZXCVBNOptions = async (password: string): Promise<unknown> => {
  const zxcvbnCommonPackage = await import('@zxcvbn-ts/language-common');
  const zxcvbnEnPackage = await import('@zxcvbn-ts/language-en');

  const options = {
    dictionary: {
      ...zxcvbnCommonPackage.dictionary,
      ...zxcvbnEnPackage.dictionary,
    },
    graphs: zxcvbnCommonPackage.adjacencyGraphs,
    translations: zxcvbnEnPackage.translations,
  };

  const matcherPwned = matcherPwnedFactory(fetch, zxcvbnOptions);
  zxcvbnOptions.addMatcher('pwned', matcherPwned);
  zxcvbnOptions.setOptions(options);

  return debounce(() => zxcvbnAsync(password), USER_INPUTS, true)();
};

export const usePasswordStrength = (password: string): PasswordStrength => {
  const [result, setResult] = useState<ZxcvbnResult>({} as ZxcvbnResult);
  const deferredPassword = useDeferredValue(password);

  useEffect(() => {
    getZXCVBNOptions(deferredPassword).then((zxcvbnResult) =>
      setResult(zxcvbnResult as ZxcvbnResult),
    );
  }, [deferredPassword]);

  return {
    score: result.score || 0,
    strengthLabel: STRENGTH_LABELS[result.score] || 'weakest',
    feedback: {
      suggestions: result.feedback?.suggestions || [],
      warning: result.feedback?.warning || '',
    },
  };
};

import React from 'react';
import { Spacing } from '../../design-system/spacing';
import { styled } from '../../utils/styled';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

type EntityTitleWithSubtitleProps = {
  readonly text: string;
  readonly subtitle: string;
};

const StyledStack = styled(Stack)`
  width: 100%;
  gap: ${({ theme }) => theme.spacing(Spacing.Small)};
`;

export function EntityTitleWithSubtitle({
  text,
  subtitle,
}: EntityTitleWithSubtitleProps) {
  return (
    <StyledStack>
      <Typography noWrap variant="h5">
        {text}
      </Typography>
      <Typography color="textSecondary" noWrap variant="caption">
        {subtitle}
      </Typography>
    </StyledStack>
  );
}

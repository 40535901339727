import {
  Skeleton as SkeletonMui,
  SkeletonProps as SkeletonPropsMui,
} from '@mui/material';
import React from 'react';

export interface SkeletonProps
  extends Pick<
    SkeletonPropsMui,
    | 'animation'
    | 'children'
    | 'component'
    | 'height'
    | 'sx'
    | 'variant'
    | 'width'
  > {
  readonly 'data-testid'?: string;
}

export function Skeleton({
  'data-testid': dataTestId,
  ...rest
}: SkeletonProps) {
  return <SkeletonMui {...rest} data-testid={dataTestId} />;
}

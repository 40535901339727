import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowForwardIcon, Box, Button, Stack, styled, Typography } from 'ui';
import { PublicNavbar } from '../../layouts/components/PublicNavbar';

const ContentWrapper = styled(Stack)`
  height: calc(100vh - 64px);
  padding-top: 64px;
  width: 100%;
  margin: 0 auto;
  max-width: 600px;
  justify-content: center;
  align-items: center;
`;

const CenterAlignedTypography = styled(Typography)`
  text-align: center;
`;

export function NotFound() {
  const navigate = useNavigate();
  return (
    <Box>
      <PublicNavbar />
      <ContentWrapper spacing={3}>
        <Typography color="primary" variant="h1">
          404
        </Typography>
        <Typography color="text.primary" variant="h2">
          Page not found
        </Typography>
        <CenterAlignedTypography color="text.secondary" variant="body">
          We couldn't find the page you requested, or it does not exist. Make
          sure you have the correct URL.
        </CenterAlignedTypography>
        <Button
          onClick={() => {
            navigate('/');
          }}
          startIcon={<ArrowForwardIcon />}
          variant="text"
        >
          Go to Homepage
        </Button>
      </ContentWrapper>
    </Box>
  );
}

import { type BoxProps as MuiBoxProps } from '@mui/material/Box';
import React from 'react';
import { StyledBox, TextFieldBox } from './OneTimePasswordInput.styles';
import { KEYBOARD_KEY } from './utils/constants/event';
import {
  getFilledArray,
  joinArrayStrings,
  mergeArrayStringFromIndex,
  updateIndex,
} from './utils/helpers/array';
import { split } from './utils/helpers/string';
import { useEvent } from './utils/hooks/useEvent';

type ValueSplit = {
  character: string;
  inputRef: React.RefObject<HTMLInputElement>;
}[];

type BoxProps = Omit<MuiBoxProps, 'onChange' | 'onBlur'>;

interface BaseMuiOtpInputProps {
  readonly value?: string;
  readonly length?: number;
  readonly onComplete?: (value: string) => void;
  readonly onChange?: (value: string) => void;
}

type MuiOtpInputProps = BoxProps & BaseMuiOtpInputProps;

export const OneTimePasswordInput = React.forwardRef(
  (props: MuiOtpInputProps, propRef: MuiOtpInputProps['ref']) => {
    const { value = '', length = 4, onChange, onComplete, ...rest } = props;

    const initialValue = React.useRef(value);
    const onCallbackEvent = useEvent(onComplete);

    const matchIsCompletedEvent = useEvent((filledStrings: string) => {
      const finalValue = filledStrings.slice(0, length);

      return {
        isCompleted: finalValue.length === length,
        finalValue,
      };
    });

    React.useEffect(() => {
      const { isCompleted, finalValue } = matchIsCompletedEvent(
        initialValue.current,
      );

      if (isCompleted) {
        onCallbackEvent(finalValue);
      }
    }, [length, onCallbackEvent, matchIsCompletedEvent]);

    const valueSplit: ValueSplit = getFilledArray(
      length as number,
      (_, index) => {
        return {
          character: (value as string)[index] || '',
          inputRef: React.createRef<HTMLInputElement>(),
        };
      },
    );

    const getIndexByInputElement = (inputElement: HTMLInputElement) => {
      return valueSplit.findIndex(({ inputRef }) => {
        return inputRef.current === inputElement;
      });
    };

    const getCharactersSplit = () => {
      return valueSplit.map(({ character }) => {
        return character;
      });
    };

    const replaceCharOfValue = (charIndex: number, charValue: string) => {
      const newValueSplit = updateIndex(
        getCharactersSplit(),
        charIndex,
        charValue,
      );

      return joinArrayStrings(newValueSplit);
    };

    const focusInputByIndex = (inputIndex: number) => {
      valueSplit[inputIndex]?.inputRef.current?.focus();
    };

    const selectInputByIndex = (inputIndex: number) => {
      valueSplit[inputIndex]?.inputRef.current?.select();
    };

    const manageCaretForNextInput = (currentInputIndex: number) => {
      if (currentInputIndex + 1 === length) {
        return;
      }

      if (valueSplit[currentInputIndex + 1].character) {
        selectInputByIndex(currentInputIndex + 1);
      } else {
        focusInputByIndex(currentInputIndex + 1);
      }
    };

    const handleOneInputChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const currentInputIndex = getIndexByInputElement(event.target);

      // Autofill from sms
      if (currentInputIndex === 0 && event.target.value.length > 1) {
        const { finalValue, isCompleted } = matchIsCompletedEvent(
          event.target.value,
        );
        onChange?.(finalValue);

        if (isCompleted) {
          onComplete?.(finalValue);
        }

        selectInputByIndex(finalValue.length - 1);

        return;
      }

      const initialChar = event.target.value[0] || '';
      const character = initialChar;

      const newValue = replaceCharOfValue(currentInputIndex, character);

      onChange?.(newValue);

      const { isCompleted, finalValue } = matchIsCompletedEvent(newValue);

      if (isCompleted) {
        onComplete?.(finalValue);
      }

      // Char is valid so go to next input
      if (character !== '') {
        // handle when the filled input is before the input selected
        if (newValue.length - 1 < currentInputIndex) {
          selectInputByIndex(newValue.length);
        } else {
          manageCaretForNextInput(currentInputIndex);
        }

        // Only for backspace so don't go to previous input if the char is invalid
      } else if (initialChar === '') {
        if (newValue.length <= currentInputIndex) {
          selectInputByIndex(currentInputIndex - 1);
        }
      }
    };

    const handleOneInputKeyDown = (
      event: React.KeyboardEvent<HTMLDivElement>,
    ) => {
      const inputElement = event.target as HTMLInputElement;
      const startPos = inputElement.selectionStart;
      const endPos = inputElement.selectionEnd;
      const currentInputIndex = getIndexByInputElement(inputElement);
      const isCaretBeforeChar = startPos === 0 && endPos === 0;

      if (inputElement.value === event.key) {
        event.preventDefault();
        manageCaretForNextInput(currentInputIndex);
      } else if (KEYBOARD_KEY.backspace === event.key) {
        if (!inputElement.value) {
          event.preventDefault();

          selectInputByIndex(currentInputIndex - 1);
          // Caret is before the character and there is a character, so remove it
        } else if (isCaretBeforeChar) {
          event.preventDefault();

          const newValue = replaceCharOfValue(currentInputIndex, '');
          onChange?.(newValue);

          if (newValue.length <= currentInputIndex) {
            selectInputByIndex(currentInputIndex - 1);
          }
        }
      } else if (KEYBOARD_KEY.left === event.key) {
        event.preventDefault();
        selectInputByIndex(currentInputIndex - 1);
      } else if (KEYBOARD_KEY.right === event.key) {
        event.preventDefault();
        selectInputByIndex(currentInputIndex + 1);
      } else if (KEYBOARD_KEY.home === event.key) {
        event.preventDefault();
        selectInputByIndex(0);
      } else if (KEYBOARD_KEY.end === event.key) {
        event.preventDefault();
        selectInputByIndex(valueSplit.length - 1);
      }
    };

    const handleOneInputPaste = (
      event: React.ClipboardEvent<HTMLDivElement>,
    ) => {
      const content = event.clipboardData.getData('text/plain');
      const inputElement = event.target as HTMLInputElement;
      // Apply from where an input is empty or equal to the input selected
      const currentInputIndex = valueSplit.findIndex(
        ({ character, inputRef }) => {
          return character === '' || inputRef.current === inputElement;
        },
      );
      const currentCharacter = getCharactersSplit();

      const characters = mergeArrayStringFromIndex(
        currentCharacter,
        split(content),
        currentInputIndex,
      ).map((character) => {
        return character;
      });

      const newValue = joinArrayStrings(characters);
      onChange?.(newValue);

      const { isCompleted, finalValue } = matchIsCompletedEvent(newValue);

      if (isCompleted) {
        onComplete?.(finalValue);
        selectInputByIndex(length - 1);
      } else {
        selectInputByIndex(newValue.length);
      }
    };

    return (
      <StyledBox ref={propRef} {...rest}>
        {valueSplit.map(({ character, inputRef }, index) => {
          return (
            <TextFieldBox
              autoComplete="one-time-code"
              autoFocus={index === 0}
              inputRef={inputRef}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onChange={handleOneInputChange}
              onFocus={(event) => {
                event.preventDefault();
                event.target.select();
              }}
              onKeyDown={(event) => {
                handleOneInputKeyDown(event);
              }}
              onPaste={(event) => {
                event.preventDefault();
                handleOneInputPaste(event);
              }}
              value={character}
            />
          );
        })}
      </StyledBox>
    );
  },
);

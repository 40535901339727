import { Spacing } from '../../design-system/spacing';
import { styled } from '../../utils/styled';
import { Card } from '../Card';
import { CardContent } from '../CardContent';
import { CardHeader } from '../CardHeader';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

export const StyledBorder = styled('div')<{ borderColor: string | undefined }>`
  margin-right: ${({ theme }) => theme.spacing(Spacing.Medium)};
  border-radius: ${({ theme }) => theme.borderRadii.outer};
  border-style: solid;
  border-width: ${({ theme }) => theme.spacing(Spacing.Tiny)};
  border-color: ${({ borderColor }) => borderColor};
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const StyledCard = styled(Card)`
  box-shadow: 0px 1px 2px 0px rgba(57, 57, 57, 0.16);
`;

export const StyledCardHeader = styled(CardHeader)`
  .MuiCardHeader-action {
    align-self: center;
  }
`;

export const RowStack = styled(Stack)`
  flex-direction: row;
`;

export const StyledStack = styled(Stack)`
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(Spacing.Tiny)};
`;

export const StyledCardContent = styled(CardContent, {
  shouldForwardProp: (prop) => prop !== 'noPadding',
})<{ noPadding?: boolean }>`
  ${({ noPadding }) => (noPadding ? 'padding: 0' : '')};
  &:last-child {
    ${({ noPadding }) => (noPadding ? 'padding: 0' : '')};
    padding-left: ${({ theme, noPadding }) =>
      noPadding ? '0' : theme.spacing(Spacing.Small)};
  }
`;

import { styled } from '../../utils/styled';
import { PageCardContainer } from '../PageCardContainer';

export const LoaderContainer = styled('div')`
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: var(--border-radii-outer, 8px);
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 600px;
  width: 511px;
`;

export const StyledPageCardContainer = styled(PageCardContainer, {
  shouldForwardProp: (prop) => prop !== 'isSmUp',
})<{
  isSmUp?: boolean;
  heightCard?: string;
  widthCard?: number;
}>`
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.palette.background.paper};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${({ isSmUp }) => (isSmUp ? '100%' : 'auto')};
  max-height: ${({ isSmUp }) => (isSmUp ? '90vh' : '100vh')};
  height: heightCard;
  max-width: 100vw;
  .MuiPaper-root.MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
  .MuiCardContent-root {
    height: 90%;
    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
      height: calc(100vh - 135px);
      min-height: 600px;
    }
  }
  .MuiCardContent-root > div {
    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
      height: 100%;
      min-height: 600px;
    }
    max-height: 844px;
    overflow: auto;
  }
`;

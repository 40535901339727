import React, { PropsWithChildren } from 'react';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import { styled } from '../../utils/styled';
import { Drawer as DrawerUI } from '../Drawer';
import { closedMixin, drawerWidth, openedMixin } from './styles';

const SideNavbarWrapper = styled('div')`
  width: 280px;
  height: 100vh;
  overflow-y: auto;
  background: ${({ theme }) => theme.palette.background.dark};
  padding: ${({ theme }) => theme.spacing(2, 2)};
`;

export const Drawer = styled(DrawerUI)(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',

  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export interface SideNavbarProps {
  readonly open?: boolean;
  readonly onClose?: () => void;
  readonly onMouseOver?: () => void;
}

export function SideNavbar({
  open,
  onClose,
  onMouseOver,
  children,
}: PropsWithChildren<SideNavbarProps>) {
  const { isLgUp } = useBreakpoints();

  if (isLgUp) {
    return (
      <Drawer
        anchor="left"
        onClose={onClose}
        onMouseLeave={onClose}
        onMouseOver={onMouseOver}
        open={open}
        variant="permanent"
      >
        <SideNavbarWrapper>{children}</SideNavbarWrapper>
      </Drawer>
    );
  }

  return (
    <Drawer anchor="left" onClose={onClose} open={open}>
      <SideNavbarWrapper>{children}</SideNavbarWrapper>
    </Drawer>
  );
}

import { CircularProgress } from '@mui/material';
import React from 'react';
import { styled } from '../../utils/styled';
import { Box } from '../Box';

const CircularBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

type LoaderProps = {
  readonly 'data-testid'?: string;
};

export function Loader({ 'data-testid': dataTestId }: LoaderProps) {
  return (
    <CircularBox data-testid={dataTestId ?? 'loader-testid'}>
      <CircularProgress />
    </CircularBox>
  );
}

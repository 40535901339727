import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { PermissionsType } from '@shared/types/forms';

export enum Page {
  dashboard = 'dashboard',
  permissions = 'permissions',
  security = 'security',
}

const caseAgnosticPages = [Page.security];

export const useAppLocation = () => {
  const location = useLocation();
  const urlPathname = location.pathname.split('/').slice(1);
  const page = Page[urlPathname[0] as Page];
  const isOrganizationPathname = useMemo(
    () => urlPathname.includes(PermissionsType.Organization),
    [urlPathname],
  );
  const shouldShowOrgOrCase = !caseAgnosticPages.includes(page);

  return {
    location,
    urlPathname,
    page,
    isOrganizationPathname,
    shouldShowOrgOrCase,
  };
};

export default useAppLocation;

import { Popover, Stack, styled, Typography } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import { Spacing } from 'ui';
import { HelpOutlineIcon } from '../Icons/HelpOutlineIcon';

export type PasswordStrengthThermometerProps = {
  readonly strength: string;
  readonly showRequirementTooltip?: boolean;
};

const StyledItemStack = styled(Stack)<{ color: string }>`
  width: 95px;
  background-color: ${({ color }) => color};
  border-radius: ${({ theme }) => theme.borderRadii.outer};
  height: ${({ theme }) => theme.spacing(Spacing.Tiny)};
  gap: ${({ theme }) => theme.spacing(Spacing.Small)};
`;

const StyledContainerStack = styled(Stack)`
  align-items: center;
`;

const getItemColors = (strength: string) => {
  const defaultColor = '#EDEDED';

  const strengthColors: { [key: string]: string } = {
    weakest: defaultColor,
    weak: '#DD5C4F',
    good: '#ECA343',
    strong: '#F1D64E',
    strongest: '#3E8682',
  };

  const strengthLevels: { [key: string]: number } = {
    weakest: 0,
    weak: 1,
    good: 2,
    strong: 3,
    strongest: 4,
  };

  const activeColor = strengthColors[strength] ?? defaultColor;
  const activeCount = strengthLevels[strength] ?? 1;

  return Array(4)
    .fill({ color: defaultColor })
    .map((item, index) =>
      index < activeCount ? { color: activeColor } : item,
    );
};

export function PasswordStrengthThermometer({
  strength,
  showRequirementTooltip,
}: PasswordStrengthThermometerProps) {
  const requirementsPopoverRef = useRef<HTMLButtonElement>(null);
  const [showRequirementsPopover, setShowRequirementsPopover] = useState(false);

  const itemsByStrength = useMemo(() => {
    const items = getItemColors(strength);

    return items.map(({ color }, index) => (
      <StyledItemStack color={color} key={`item-${index + 1}`} spacing={1} />
    ));
  }, [strength]);

  const formattedPasswordStrength = useMemo(
    () => strength.charAt(0).toUpperCase() + strength.slice(1),
    [strength],
  );

  return (
    <StyledContainerStack direction="row" spacing={1}>
      {itemsByStrength}
      <Stack>
        {strength ? (
          <Typography variant="caption">{formattedPasswordStrength}</Typography>
        ) : null}
      </Stack>
      <Stack>
        {showRequirementTooltip ? (
          <>
            <HelpOutlineIcon
              aria-label="requirements-icon"
              onClick={() => setShowRequirementsPopover(true)}
            />
            <Popover
              anchorEl={requirementsPopoverRef.current}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={() => setShowRequirementsPopover(false)}
              open={showRequirementsPopover}
            >
              <Stack padding={4} spacing={2}>
                <Typography variant="bodyStrong">
                  <Typography variant="body">Requirements:</Typography>
                </Typography>
                <Typography variant="body">- Lower Case Letter</Typography>
                <Typography variant="body">- Upper Case Letter</Typography>
                <Typography variant="body">- Number</Typography>
                <Typography variant="body">- Special Character</Typography>
                <Typography variant="body">- At least 8 characters</Typography>
              </Stack>
            </Popover>
          </>
        ) : null}
      </Stack>
    </StyledContainerStack>
  );
}

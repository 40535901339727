import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import * as React from 'react';
import { Spacing } from '../../design-system/spacing';
import { styled } from '../../utils/styled';
import { Box } from '../Box';
import { IconButton } from '../IconButton';
import { CloseIcon } from '../Icons/CloseIcon';
import { Typography } from '../Typography';

const StyledAlert = styled(Alert)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  border: none;
  border-left: ${({ theme }) => theme.borderRadii.outer} solid
    ${({ severity, theme }) => severity && theme.palette[severity].main};
  border-radius: ${({ theme }) => theme.borderRadii.outer};
  padding: 22px 24px 22px 16px;
  width: 500px;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(Spacing.Medium)};

  .MuiAlert-message,
  .MuiButtonBase-root,
  .MuiAlert-icon {
    padding: 0;
  }
`;
const StyledBox = styled(Box)`
  width: 100%;
`;

export type ToastType = 'success' | 'info' | 'warning' | 'error';
export interface ToastProps {
  readonly message: string;
  readonly type: ToastType;
  readonly open: boolean;
  readonly onClose: () => void;
}

export function Toast({ message, type = 'info', open, onClose }: ToastProps) {
  return (
    <StyledBox>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={onClose}
        open={open}
      >
        <StyledAlert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={onClose}
              size="small"
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={type}
          variant="outlined"
        >
          <Typography variant="body">{message}</Typography>
        </StyledAlert>
      </Snackbar>
    </StyledBox>
  );
}

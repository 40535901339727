import { FormControlLabel, SwitchProps as SwitchPropsMui } from '@mui/material';
import React from 'react';
import { ColorNeutral300, ColorNeutral600 } from '../../design-system/colors';
import { Spacing } from '../../design-system/spacing';
import { styled } from '../../utils/styled';
import { Typography } from '../Typography';
import { StyledSwitchUi } from './Switch.styles';

const StyledTypography = styled(Typography)`
  margin-left: ${({ theme }) => theme.spacing(Spacing.Tiny)};
`;

export interface SwitchProps
  extends Pick<SwitchPropsMui, 'checked' | 'disabled' | 'onChange'> {
  readonly value?: string;
  readonly label?: string;
  readonly disabled?: boolean;
}

export function Switch({ disabled, label, value, ...props }: SwitchProps) {
  return (
    <FormControlLabel
      control={
        <StyledSwitchUi
          inputProps={{ 'aria-label': label }}
          name="switch"
          type="checkbox"
          {...props}
        />
      }
      disabled={disabled}
      label={
        <StyledTypography
          color={disabled ? ColorNeutral300 : ColorNeutral600}
          variant="body"
        >
          {label}
        </StyledTypography>
      }
      value={value}
    />
  );
}
